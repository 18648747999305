// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 {
  width: 270px;
  height: 110px;
  border: 1px solid rgba(255, 255, 255, 0.6509803922);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 .trustedByContainer_logo__nf8ao {
  height: -webkit-fill-available;
  min-height: 100%; /* Firefox support */
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 .trustedByContainer_logoMobile__1rVPM {
  display: none;
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_txtWrapper__e5Lj4 {
  height: 38px;
  background-color: #fff;
  display: flex;
  width: 272px;
  flex-direction: column;
  justify-content: flex-end;
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_txtWrapper__e5Lj4 .trustedByContainer_txt__vCxvo {
  color: #000;
  text-align: center;
  font-family: "Blender Pro Medium", sans-serif;
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_txtWrapper__e5Lj4 .trustedByContainer_blueLine__fXECH {
  width: 31px;
  height: 1px;
  background-color: #0085ff;
  border: 1px solid #0085ff;
  margin-left: auto;
  margin-top: inherit;
}
.trustedByContainer_wrapper__8Eli2 .trustedByContainer_txtWrapperAr__S5oa9 {
  height: 50px;
}

@media (max-width: 960px) {
  .trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 {
    width: 163px;
    height: 120px;
  }
  .trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 .trustedByContainer_logo__nf8ao {
    display: none;
  }
  .trustedByContainer_wrapper__8Eli2 .trustedByContainer_container__t0nQ6 .trustedByContainer_logoMobile__1rVPM {
    display: block;
  }
  .trustedByContainer_wrapper__8Eli2 .trustedByContainer_txtWrapper__e5Lj4 {
    width: 165px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/trustedByContainer/trustedByContainer.module.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;EACA,mDAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAAJ;AAEI;EACE,8BAAA;EACA,gBAAA,EAAA,oBAAA;AAAN;AAEI;EACE,aAAA;AAAN;AAIE;EACE,YAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;AAFJ;AAII;EACE,WAAA;EACA,kBAAA;EACA,6CAAA;AAFN;AAKI;EACE,WAAA;EACA,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;AAHN;AAOE;EACE,YAAA;AALJ;;AASA;EAEI;IACE,YAAA;IACA,aAAA;EAPJ;EASI;IACE,aAAA;EAPN;EASI;IACE,cAAA;EAPN;EAWE;IACE,YAAA;IACA,eAAA;EATJ;AACF","sourcesContent":[".wrapper {\r\n  .container {\r\n    width: 270px;\r\n    height: 110px;\r\n    border: 1px solid #ffffffa6;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n\r\n    .logo {\r\n      height: -webkit-fill-available;\r\n      min-height: 100%; /* Firefox support */\r\n    }\r\n    .logoMobile {\r\n      display: none;\r\n    }\r\n  }\r\n\r\n  .txtWrapper {\r\n    height: 38px;\r\n    background-color: #fff;\r\n    display: flex;\r\n    width: 272px;\r\n    flex-direction: column;\r\n    justify-content: flex-end;\r\n\r\n    .txt {\r\n      color: #000;\r\n      text-align: center;\r\n      font-family: \"Blender Pro Medium\", sans-serif;\r\n    }\r\n\r\n    .blueLine {\r\n      width: 31px;\r\n      height: 1px;\r\n      background-color: #0085ff;\r\n      border: 1px solid #0085ff;\r\n      margin-left: auto;\r\n      margin-top: inherit;\r\n    }\r\n  }\r\n\r\n  .txtWrapperAr {\r\n    height: 50px;\r\n  }\r\n}\r\n\r\n@media (max-width: 960px) {\r\n  .wrapper {\r\n    .container {\r\n      width: 163px;\r\n      height: 120px;\r\n\r\n      .logo {\r\n        display: none;\r\n      }\r\n      .logoMobile {\r\n        display: block;\r\n      }\r\n    }\r\n\r\n    .txtWrapper {\r\n      width: 165px;\r\n      font-size: 14px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `trustedByContainer_wrapper__8Eli2`,
	"container": `trustedByContainer_container__t0nQ6`,
	"logo": `trustedByContainer_logo__nf8ao`,
	"logoMobile": `trustedByContainer_logoMobile__1rVPM`,
	"txtWrapper": `trustedByContainer_txtWrapper__e5Lj4`,
	"txt": `trustedByContainer_txt__vCxvo`,
	"blueLine": `trustedByContainer_blueLine__fXECH`,
	"txtWrapperAr": `trustedByContainer_txtWrapperAr__S5oa9`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__MhatS {
  display: flex;
  justify-content: space-between;
  padding: 26px 80px;
}
.header_header__MhatS .header_leftSide__CMYS4 {
  display: flex;
  align-items: center;
  gap: 16px;
}
.header_header__MhatS .header_leftSide__CMYS4 .header_btn__wWJ2A {
  font-family: "Blender Pro Book", sans-serif;
  border: 1px solid #ffffff;
  padding: 10px 37px;
  font-size: 20px;
  text-transform: uppercase;
  background-color: #000;
  color: #ffffff;
  margin-right: 16px;
  cursor: pointer;
}

@media (max-width: 901px) {
  .header_header__MhatS {
    padding: 26px 16px;
  }
  .header_header__MhatS .header_leftSide__CMYS4 .header_btn__wWJ2A {
    padding: 6px 12px 6px 12px;
    font-size: 14px;
    height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,2CAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AACN;;AAIA;EACE;IACE,kBAAA;EADF;EAGI;IACE,0BAAA;IACA,eAAA;IACA,YAAA;EADN;AACF","sourcesContent":[".header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 26px 80px;\r\n\r\n  .leftSide {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 16px;\r\n\r\n    .btn {\r\n      font-family: \"Blender Pro Book\", sans-serif;\r\n      border: 1px solid #ffffff;\r\n      padding: 10px 37px;\r\n      font-size: 20px;\r\n      text-transform: uppercase;\r\n      background-color: #000;\r\n      color: #ffffff;\r\n      margin-right: 16px;\r\n      cursor: pointer;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 901px) {\r\n  .header {\r\n    padding: 26px 16px;\r\n    .leftSide {\r\n      .btn {\r\n        padding: 6px 12px 6px 12px;\r\n        font-size: 14px;\r\n        height: 32px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__MhatS`,
	"leftSide": `header_leftSide__CMYS4`,
	"btn": `header_btn__wWJ2A`
};
export default ___CSS_LOADER_EXPORT___;

import { FC } from "react";
import styles from "./partners.module.scss";

interface PartnersProps {
  language?: string;
}

const Partners: FC<PartnersProps> = ({ language }) => {
  const financialTransactionsEn =
    "For financial transactions screening and audit, you may contact our partner Andrew - VP of citibank with over 20 years of hands-on experience with sanctions and screening.";
  const financialTransactionsAr = "لفحص وتدقيق المعاملات المالية، يمكنك الاتصال بشريكنا أندري - نائب رئيس سيتي بنك الذي يتمتع بخبرة عملية تزيد عن 20 عامًا في مجال العقوبات المالية وفحص المعاملات"
  const cyberSecurityInfoEn =
    "For cybersecurity end-to-end audit of your company, product, or blockchain, feel free to contact our partners at Hexens, who already secured over 55 billion USD.";
  const cyberSecurityInfoAr = "لإجراء تدقيق شامل للأمن السيبراني لشركتك أو منتجك أو بتقنية سلسلة الكتل (Blockchain)، لا تتردد في الاتصال بشركائنا في Hexens، الذين حصلوا بالفعل على أكثر من 55 مليار دولار أمريكي."
  const financialTransactions = language === "ar" ? financialTransactionsAr : financialTransactionsEn;
  const cyberSecurityInfo = language === "ar" ? cyberSecurityInfoAr : cyberSecurityInfoEn;

  const partnersInfo = [
    {
      img: "/icons/financial-transaction-logo.png",
      text: financialTransactions,
      link: "https://riskintel.co"
    },
    {
      img: "/icons/cybersecurity-logo.png",
      text: cyberSecurityInfo,
      link: "https://hexens.io",
    },
  ];
  return (
    <div>
      <h2 className={styles.title}>/ Partners</h2>
      <div className={styles.wrapper}>
        {partnersInfo.map((partner, index) => (
          <div key={index} className={styles.partner}>
            <img src={partner.img} alt="partner logo" width={80} height={80} />
            <p className={styles.txt}>
              {partner.text}{" "}
              {partner.link && (
                <a
                  href={partner.link}
                  target={"_blank"}
                  className={styles.link}
                  rel="noreferrer"
                >
                  {partner.link}.
                </a>
              )}{" "}
            </p>
          </div>
        ))}

        <div></div>
      </div>
    </div>
  );
};

export default Partners;

import React, { FC } from "react";
import styles from "./trustedByContainer.module.scss";
import classNames from "classnames";

interface TrustedByContainerProps {
  imgSrc: string;
  txt: string;
  language?: string;
  imgScrMobile: string;
}

const TrustedByContainer: FC<TrustedByContainerProps> = ({
  imgSrc,
  txt,
  language,
  imgScrMobile,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={imgSrc} alt={txt} className={styles.logo} />
        <img src={imgScrMobile} alt={txt} className={styles.logoMobile} width={110} height={100} />
      </div>
      <div
        className={classNames(styles.txtWrapper, {
          [styles.txtWrapperAr]: language === "ar",
        })}
      >
        <span className={styles.txt}>{txt}</span>
        <hr className={styles.blueLine} />
      </div>
    </div>
  );
};

export default TrustedByContainer;

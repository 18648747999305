import styles from "./footer.module.scss";
import { Contacts } from "../contacts";
import { FC } from "react";

type FooterProps = {
  language?: string;
};

const Footer: FC<FooterProps> = ({ language }) => {
  const infoAr = [
    {
      title: "/ صيغة التعاون",
      description: "استشارات، عقود بدوام كامل/جزئي.",
    },
    {
      title: "/ معايير المشروع ",
      description: "طموح عالي، تأثير كبير، غير مرتبط بدول محددة.",
    },
  ];

  const infoEn = [
    {
      title: "/ cooperation format",
      description: "Consulting, advisory, full/part-time contracts.",
    },
  ];
  const info = language === "ar" ? infoAr : infoEn;

  const contactsTxtEn = "/ contacts";
  const contactsTxtAr = "/ جهات الاتصال";
  const contactsTxt = language === "ar" ? contactsTxtAr : contactsTxtEn;

  return (
    <footer className={styles.footer}>
      <div>
        {info.map((item, index) => (
          <div key={index} className={styles.wrapper}>
            <h4 className={styles.title}>{item.title}</h4>
            <p className={styles.description}>{item.description}</p>
          </div>
        ))}
        <div>
          <h4 className={styles.title}> {contactsTxt} </h4>
          <Contacts language={language} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trustedBy_wrapper__MpeEU {
  padding-top: 40px;
  padding-bottom: 115px;
}
.trustedBy_wrapper__MpeEU .trustedBy_title__1T6J2 {
  margin: 0;
  padding-bottom: 36px;
}
.trustedBy_wrapper__MpeEU .trustedBy_containers__Lm9ia {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 21px;
  grid-column-gap: 20px;
}

@media (max-width: 1195px) {
  .trustedBy_wrapper__MpeEU .trustedBy_containers__Lm9ia {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
@media (max-width: 1155px) {
  .trustedBy_wrapper__MpeEU {
    padding-bottom: 114px;
  }
  .trustedBy_wrapper__MpeEU .trustedBy_containers__Lm9ia {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/trustedBy/trustedBy.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;AACF;AACE;EACE,SAAA;EACA,oBAAA;AACJ;AAEE;EACE,aAAA;EACA,4DAAA;EACA,kBAAA;EACA,qBAAA;AAAJ;;AAIA;EAEI;IACE,4DAAA;EAFJ;AACF;AAMA;EACE;IACE,qBAAA;EAJF;EAKE;IACE,aAAA;IACA,eAAA;IACA,SAAA;IACA,uBAAA;EAHJ;AACF","sourcesContent":[".wrapper {\r\n  padding-top: 40px;\r\n  padding-bottom: 115px;\r\n\r\n  .title {\r\n    margin: 0;\r\n    padding-bottom: 36px;\r\n  }\r\n\r\n  .containers {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));\r\n    grid-row-gap: 21px;\r\n    grid-column-gap: 20px;\r\n  }\r\n}\r\n\r\n@media (max-width: 1195px) {\r\n  .wrapper {\r\n    .containers {\r\n      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 1155px) {\r\n  .wrapper {\r\n    padding-bottom: 114px;\r\n    .containers {\r\n      display: flex;\r\n      flex-wrap: wrap;\r\n      gap: 16px;\r\n      justify-content: center;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `trustedBy_wrapper__MpeEU`,
	"title": `trustedBy_title__1T6J2`,
	"containers": `trustedBy_containers__Lm9ia`
};
export default ___CSS_LOADER_EXPORT___;

import styles from "./scrollToTop.module.scss";
import {FC} from "react";

export interface ScrollToTopProps {
  language?: string;
}

const ScrollToTop:FC<ScrollToTopProps> = ({language}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const txtEn = "To Top";
  const txtAr = "اذهب إلى الأعلى";

  const txt = language === 'ar' ? txtAr : txtEn;

  return (
    <div className={styles.wrapper}>
      <button onClick={scrollToTop} className={styles.btn}>
        {txt}
        <img
          src="/icons/scrollToTop.png"
          className={styles.topArrow}
          width={50}
          height={50}
        />
      </button>
    </div>
  );
};

export default ScrollToTop;

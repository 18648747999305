// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageSwitcher_languageSwitcher__1Ib8K {
  display: flex;
  align-items: center;
  gap: 3.5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageSwitcher/LanguageSwitcher.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;EACA,eAAA;EACA,yBAAA;EACA,eAAA;AACF","sourcesContent":[".languageSwitcher {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 3.5px;\r\n  cursor: pointer;\r\n  text-transform: uppercase;\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSwitcher": `LanguageSwitcher_languageSwitcher__1Ib8K`
};
export default ___CSS_LOADER_EXPORT___;

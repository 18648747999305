import { FC } from "react";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";

import styles from "./animatedFrame.module.scss";

interface AnimatedFrameProps {
  imageUrl: string;
  className?: string;
  width: number;
  height: number;
  rotate?: boolean;
  line?: boolean;
  lineClassName?: string;
  arabic?: boolean;
}

const AnimatedFrame: FC<AnimatedFrameProps> = ({
  imageUrl,
  className,
  width,
  height,
  rotate,
  arabic,
  line,
  lineClassName,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1.0,
  });

  return (
    <div className={classNames(styles.frames, className)} ref={ref}>
      {line && (
        <img
          src={"/icons/line.svg"}
          className={classNames(styles.line, lineClassName, {
            [styles.lineVisible]: inView,
            [styles.lineArabic]: arabic,
          })}
        />
      )}
      {Array.from({ length: 4 }).map((_, index) => (
        <img
          key={index}
          src={imageUrl}
          width={width}
          height={height}
          alt={"frame animated"}
          className={classNames(
            "animate__fadeInDown",
            styles.frame,
            index === 0 && styles.first,
            index === 1 && styles.second,
            index === 2 && styles.third,
            index === 3 && styles.fourth,
            inView && index === 0 && styles.firstVisible,
            inView && index === 1 && styles.secondVisible,
            inView && index === 2 && styles.thirdVisible,
            inView && index === 3 && styles.fourthVisible,
            rotate && styles.rotate
          )}
        />
      ))}
    </div>
  );
};

export default AnimatedFrame;

import React, { FC, useEffect, useState } from "react";

import { Header } from "../../components/header";
import { Hero } from "../../components/hero";
import { InfoSection } from "../../components/infoSection";
import ProjectTypes from "../../components/projectTypes/projectTypes";
import { Contribution } from "../../components/contribution";
import { TrustedBy } from "../../components/trustedBy";
import { Footer } from "../../components/footer";
import { ScrollToTop } from "../../components/scrollToTop";
import ContactsModal from "../../components/contactsModal/contactsModal";
import Partners from "../../components/partners";

import styles from "../../App.module.scss";

type HomeProps = {
  language?: string;
};
const Home: FC<HomeProps> = ({ language }) => {
  const [openContactModal, setOpenContactModal] = useState(false);

  useEffect(() => {
    if (openContactModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  }, [openContactModal]);

  const projectTypesEn = [
    {
      name: "CYBER INTELLIGENCE SYSTEMS",
      icon: "/icons/triangle.png",
      gif: "/gifs/triangle.mp4",
    },
    {
      name: "DIGITAL FORENSICS LABS",
      icon: "/icons/x.png",
      gif: "/gifs/digital-forensics.mp4",
    },
    {
      name: "MULTI-FACTOR BIOMETRIC AUTHENTICATION",
      icon: "/icons/round.png",
      gif: "/gifs/biometric.mp4",
    },
    {
      name: "GOVERNMENTAL ERPs AND CRMs",
      icon: "/icons/square.png",
      gif: "/gifs/governmental.mp4",
    },
  ];

  const projectTypesAr = [
    {
      name: "أنظمة الاستخبارات السيبرانية",
      icon: "/icons/triangle.png",
      gif: "/gifs/triangle.mp4",
    },
    {
      name: "مختبرات الطب الشرعي الرقمي",
      icon: "/icons/x.png",
      gif: "/gifs/digital-forensics.mp4",
    },
    {
      name: "المصادقة البيومترية متعددة العوامل",
      icon: "/icons/round.png",
      gif: "/gifs/biometric.mp4",
    },
    {
      name: "أنظمة تخطيط موارد المؤسسات (ERP) الحكومية وإدارة علاقات العملاء (CRM)",
      icon: "/icons/square.png",
      gif: "/gifs/governmental.mp4",
    },
  ];

  const projectTypeTitleEn = "/ project types we have built from scratch";
  const projectTypeTitleAr = "/ أنواع المشاريع التي قمنا ببنائها من الصفر";
  const projectTypes = language === "ar" ? projectTypesAr : projectTypesEn;
  const projectTypeTitle =
    language === "ar" ? projectTypeTitleAr : projectTypeTitleEn;

  return (
    <div style={{ direction: language === "ar" ? "rtl" : "ltr" }}>
      <Header openContacts={setOpenContactModal} language={language} />
      <Hero language={language} />
      <div className={styles.mainPage}>
        <InfoSection language={language} />
        <ProjectTypes
          projectTypes={projectTypes}
          projectTypeTitle={projectTypeTitle}
          language={language}
        />
        <Contribution language={language} />
        <TrustedBy language={language} />
        <Partners language={language} />
        <Footer language={language} />
        <ScrollToTop language={language} />
      </div>
      {openContactModal && (
        <ContactsModal onClick={setOpenContactModal} language={language} />
      )}
    </div>
  );
};

export default Home;

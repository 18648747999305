// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/map.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__NjMd5 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: round;
  padding-top: 117px;
}
.footer_footer__NjMd5 .footer_wrapper__3XTBO {
  padding-bottom: 71px;
}
.footer_footer__NjMd5 .footer_wrapper__3XTBO .footer_description__RkyOP {
  font-size: 24px;
  line-height: 28.8px;
  margin: 0;
}
.footer_footer__NjMd5 .footer_title__JaZwJ {
  font-size: 32px;
  line-height: 35.2px;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 901px) {
  .footer_footer__NjMd5 {
    margin-bottom: 51px;
    padding-top: unset;
  }
  .footer_footer__NjMd5 .footer_wrapper__3XTBO {
    padding-top: 35px;
    padding-bottom: 61px;
  }
  .footer_footer__NjMd5 .footer_wrapper__3XTBO .footer_description__RkyOP {
    font-size: 20px;
  }
  .footer_footer__NjMd5 .footer_title__JaZwJ {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,wBAAA;EACA,kBAAA;AACF;AACE;EACE,oBAAA;AACJ;AACI;EACE,eAAA;EACA,mBAAA;EACA,SAAA;AACN;AAGE;EACE,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;AADJ;;AAKA;EACE;IACE,mBAAA;IACA,kBAAA;EAFF;EAIE;IACE,iBAAA;IACA,oBAAA;EAFJ;EAII;IACE,eAAA;EAFN;EAME;IACE,eAAA;EAJJ;AACF","sourcesContent":[".footer {\r\n  background-image: url(\"/images/map.png\");\r\n  background-repeat: round;\r\n  padding-top: 117px;\r\n\r\n  .wrapper {\r\n    padding-bottom: 71px;\r\n\r\n    .description {\r\n      font-size: 24px;\r\n      line-height: 28.8px;\r\n      margin: 0;\r\n    }\r\n  }\r\n\r\n  .title {\r\n    font-size: 32px;\r\n    line-height: 35.2px;\r\n    text-transform: uppercase;\r\n    margin: 0;\r\n  }\r\n}\r\n\r\n@media (max-width: 901px) {\r\n  .footer {\r\n    margin-bottom: 51px;\r\n    padding-top: unset;\r\n\r\n    .wrapper {\r\n      padding-top: 35px;\r\n      padding-bottom: 61px;\r\n\r\n      .description {\r\n        font-size: 20px;\r\n      }\r\n    }\r\n\r\n    .title {\r\n      font-size: 24px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__NjMd5`,
	"wrapper": `footer_wrapper__3XTBO`,
	"description": `footer_description__RkyOP`,
	"title": `footer_title__JaZwJ`
};
export default ___CSS_LOADER_EXPORT___;

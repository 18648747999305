// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners_wrapper__vM1Dj {
  display: flex;
  gap: 98px;
  padding-top: 36px;
}
.partners_wrapper__vM1Dj .partners_partner__NKayQ {
  display: flex;
  gap: 17px;
  align-items: center;
}
.partners_wrapper__vM1Dj .partners_partner__NKayQ .partners_txt__CL26A {
  font-size: 20px;
  line-height: 24px;
}
.partners_wrapper__vM1Dj .partners_partner__NKayQ .partners_txt__CL26A .partners_link__BOGJu {
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #fff;
}

@media (max-width: 960px) {
  .partners_wrapper__vM1Dj {
    flex-direction: column;
    gap: 0;
    padding-top: 24px;
  }
  .partners_wrapper__vM1Dj .partners_partner__NKayQ {
    gap: 33px;
    padding-bottom: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/partners/partners.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,iBAAA;AACN;AACM;EACE,qBAAA;EACA,WAAA;EACA,6BAAA;AACR;;AAKA;EACE;IACE,sBAAA;IACA,MAAA;IACA,iBAAA;EAFF;EAIE;IACE,SAAA;IACA,oBAAA;EAFJ;AACF","sourcesContent":[".wrapper {\r\n  display: flex;\r\n  gap: 98px;\r\n  padding-top: 36px;\r\n\r\n  .partner {\r\n    display: flex;\r\n    gap: 17px;\r\n    align-items: center;\r\n\r\n    .txt {\r\n      font-size: 20px;\r\n      line-height: 24px;\r\n\r\n      .link {\r\n        text-decoration: none;\r\n        color: #fff;\r\n        border-bottom: 1px solid #fff;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 960px) {\r\n  .wrapper {\r\n    flex-direction: column;\r\n    gap: 0;\r\n    padding-top: 24px;\r\n\r\n    .partner {\r\n      gap: 33px;\r\n      padding-bottom: 60px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `partners_wrapper__vM1Dj`,
	"partner": `partners_partner__NKayQ`,
	"txt": `partners_txt__CL26A`,
	"link": `partners_link__BOGJu`
};
export default ___CSS_LOADER_EXPORT___;

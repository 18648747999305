import styles from "./contacts.module.scss";
import React, {FC} from "react";

type ContactsProps = {
    language?: string;

}
const Contacts:FC<ContactsProps> = ({language}) => {
    const telegramUrl = "https://t.me/highness";
    const telegramUsernameEn = "@highness";
    const telegramUsernameAr = "highness@";
    const telegramUsername = language === 'ar' ? telegramUsernameAr : telegramUsernameEn;

    const telegramTxtEn = "Telegram: ";
    const telegramTxtAr = "تليجرام: ";
    const telegramTxt = language === 'ar' ? telegramTxtAr : telegramTxtEn;

    const emailTxtEn = "Emails: ";
    const emailsTxtAr = "بريد إلكتروني: ";
    const emailsTxt = language === 'ar' ? emailsTxtAr : emailTxtEn;

    const basedInTxtEn = "Based in UAE-Armenia";
    const basedInTxtAr = "مقرنا في دولة الإمارات العربية المتحدة وأرمينيا";
    const basedInTxt = language === 'ar' ? basedInTxtAr : basedInTxtEn;

    const languagesTxtEn = "Languages: English, Russian";
    const languagesTxtAr = "اللغات: الإنجليزية، الروسية"
   const languagesTxt = language === 'ar' ? languagesTxtAr : languagesTxtEn;

    return (
        <div>
            <div className={styles.firstWrapper}>
            <span className={styles.infoTxt}>
              {telegramTxt} <a href={telegramUrl}
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.telegram}
            >
                 {telegramUsername}
              </a>
            </span>
                <span className={styles.infoTxt}>
             {emailsTxt} <a href={"mailto:wolf@elea.co"}> wolf@elea.co </a> |
              <a href={"mailto:natalie@elea.co"}> natalie@elea.co </a>
            </span>
            </div>
            <div>
                <span className={styles.infoTxt}> {basedInTxt} </span>
                <span className={styles.infoTxt}>{languagesTxt}</span>
            </div>
        </div>
    )
};

export default Contacts;
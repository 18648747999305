import { FC, useState } from "react";
import { Container } from "../container";
import { AnimatedFrame } from "../animatedFrame";
import classNames from "classnames";

import Tooltip from "../tooltip";

import styles from "./projectTypes.module.scss";

interface ProjectTypesProps {
  projectTypes: any;
  projectTypeTitle?: string;
  language?: string;
}

const ProjectTypes: FC<ProjectTypesProps> = ({
  projectTypes,
  projectTypeTitle,
  language,
}) => {
  const [hoverStates, setHoverStates] = useState<boolean[]>(
      Array(projectTypes.length).fill(false)
  );

  const textAr =
    "كان جزء كبير من عملنا يتعلق بتنظيف الفوضى التي خلفتها الشركات الأربع الكبرى.";

  const setHoverActive = (index: number, value: boolean) => {
    setHoverStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? value : state))
    );
  };

  return (
    <section className={styles.section}>
      <div className={classNames(styles.firstWrapper, styles.mainWrapper)}>
        <hr
          className={classNames(styles.liner, {
            [styles.arabicLiner]: language === "ar",
          })}
        />
        <h2 className={styles.title}>{projectTypeTitle}</h2>
        {projectTypes.map(
          (
            projectType: { name: string; icon: string; gif: any },
            index: any
          ) => (
            <Tooltip          
              setHoverActive={setHoverActive}
              gif={projectType.gif}
              key={index}
              index={index}
            >
              <Container
                hoverActive={hoverStates[index]}
                title={projectType.name}
                isProjectType
                gif={projectType.gif}
                className={classNames(styles["container"], {})}
                imgUrl={projectType.icon}
              />
            </Tooltip>
          )
        )}
      </div>
      <div className={styles.secondWrapper}>
        {language === "ar" ? (
          <p className={styles.txt}>
            <hr className={styles.liner} />
            {textAr}
          </p>
        ) : (
          <p className={styles.txt}>
            <hr className={styles.liner} />
            Big chunk of our work was related to cleaning up the mess after Big
            Four companies.
          </p>
        )}
        <div className={styles.frameWrapper}>
          <AnimatedFrame
            imageUrl={"/images/vector.png"}
            className={styles.frame}
            width={312.54}
            height={101}
            line
            lineClassName={styles["line"]}
          />
        </div>
      </div>
    </section>
  );
};

export default ProjectTypes;

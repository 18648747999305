// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_tooltip__ycbtL {
  position: absolute;
  width: 200px;
  height: 150px;
  visibility: hidden;
}
.tooltip_tooltip__ycbtL .tooltip_video__SzCy8 {
  border: 1px solid #2f2f2f;
}

.tooltip_none__UE8zg {
  display: none;
}

.tooltip_visible__AOdMI {
  visibility: visible;
}

@media (max-width: 960px) {
  .tooltip_visible__AOdMI {
    visibility: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE;IACE,kBAAA;EAAF;AACF","sourcesContent":[".tooltip {\r\n  position: absolute;\r\n  width: 200px;\r\n  height: 150px;\r\n  visibility: hidden;\r\n\r\n  .video {\r\n    border: 1px solid #2f2f2f;\r\n  }\r\n}\r\n\r\n.none {\r\n  display: none;\r\n}\r\n\r\n.visible {\r\n  visibility: visible;\r\n}\r\n\r\n@media(max-width: 960px) {\r\n  .visible {\r\n    visibility: hidden;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip_tooltip__ycbtL`,
	"video": `tooltip_video__SzCy8`,
	"none": `tooltip_none__UE8zg`,
	"visible": `tooltip_visible__AOdMI`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contribution_wrapper__WS6i1 {
  padding-top: 75px;
  padding-bottom: 122px;
}
.contribution_wrapper__WS6i1 .contribution_title__CWLN7 {
  padding-bottom: 43px;
}
.contribution_wrapper__WS6i1 .contribution_container__Szhp5:first-child {
  margin-top: 0;
}
.contribution_wrapper__WS6i1 .contribution_container__Szhp5 {
  text-transform: uppercase;
}
.contribution_wrapper__WS6i1 .contribution_item-1__xFJZR {
  margin-top: 24px;
}

@media (max-width: 901px) {
  .contribution_wrapper__WS6i1 .contribution_title__CWLN7 {
    padding-bottom: 24px;
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/contribution/contribution.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;AACF;AACE;EACE,oBAAA;AACJ;AACE;EACE,aAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EAEI;IACE,oBAAA;IACA,eAAA;EAFJ;AACF","sourcesContent":[".wrapper {\r\n  padding-top: 75px;\r\n  padding-bottom: 122px;\r\n\r\n  .title {\r\n    padding-bottom: 43px;\r\n  }\r\n  .container:first-child {\r\n    margin-top: 0;\r\n  }\r\n\r\n  .container {\r\n    text-transform: uppercase;\r\n  }\r\n  .item-1 {\r\n    margin-top: 24px;\r\n  }\r\n}\r\n\r\n@media (max-width: 901px) {\r\n  .wrapper {\r\n    .title {\r\n      padding-bottom: 24px;\r\n      font-size: 24px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `contribution_wrapper__WS6i1`,
	"title": `contribution_title__CWLN7`,
	"container": `contribution_container__Szhp5`,
	"item-1": `contribution_item-1__xFJZR`
};
export default ___CSS_LOADER_EXPORT___;

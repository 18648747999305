// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts_firstWrapper__8LZdD {
  padding-bottom: 40px;
}

.contacts_infoTxt__iros0 {
  display: block;
  font-size: 24px;
  color: #fff;
  line-height: 33.6px;
}
.contacts_infoTxt__iros0 > a {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/contacts/contacts.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":[".firstWrapper {\r\n  padding-bottom: 40px;\r\n}\r\n\r\n.infoTxt {\r\n  display: block;\r\n  font-size: 24px;\r\n  color: #fff;\r\n  line-height: 33.6px;\r\n\r\n  > a {\r\n    color: #fff;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstWrapper": `contacts_firstWrapper__8LZdD`,
	"infoTxt": `contacts_infoTxt__iros0`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";

import styles from "./tooltip.module.scss";

export interface TooltipProps {
  setHoverActive?: (index: number, value: boolean) => void;
  children?: React.ReactNode;
  onClick?: () => void;
  gif?: string;
  index: number;
}

const Tooltip: FC<TooltipProps> = ({
  children,
  onClick,
  gif,
  setHoverActive,
  index,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const wrappedElementRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (isHovering) {
      setTooltipVisible(true);
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
        hoverTimeout.current = null;
      }
      if (videoRef.current) {
        videoRef.current.play();
      }
    } else {
      hoverTimeout.current = setTimeout(() => {
        if (!isHovering && videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      }, 1000);
    }
  }, [isHovering]);

  const handleMouseOver = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setIsHovering(true);
    if (setHoverActive) {
      setHoverActive(index, true);
    }
  };

  const handleMouseLeave = () => {
    if (setHoverActive) {
      setHoverActive(index, false);
    }
    hoverTimeout.current = setTimeout(() => {
      setIsHovering(false);
    }, 1000);
  };

  useEffect(() => {
    const tooltip = tooltipRef.current;
    const wrappedElement = wrappedElementRef.current;

    if (!tooltip || !wrappedElement) {
      return;
    }

    const mouseOverEventListener = (): void => {
      setTooltipVisible(true);
    };

    const moveLeaveEventListener = (): void => {
      setTooltipVisible(false);
    };

    const mouseMoveEventListener = (e: MouseEvent): void => {
      const tooltipWidth = tooltip.clientWidth;

      tooltip.style.top = `${e.pageY + -170}px`;
      tooltip.style.left = `${e.pageX - tooltipWidth + 320}px`;
      tooltip.style.transform = "translate(-50%, -50%)";
    };

    wrappedElement.addEventListener("mousemove", mouseMoveEventListener);
    wrappedElement.addEventListener("mouseover", mouseOverEventListener);
    wrappedElement.addEventListener("mouseout", moveLeaveEventListener);

    return () => {
      wrappedElement.removeEventListener("mouseover", mouseOverEventListener);
      wrappedElement.removeEventListener("mouseleave", moveLeaveEventListener);
      wrappedElement.removeEventListener("mousemove", mouseMoveEventListener);
    };
  }, []);

  return (
    <>
      <div
        ref={wrappedElementRef}
        onClick={onClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>

      {createPortal(
        <div
          className={classNames(styles.tooltip, {
            [styles.visible]: tooltipVisible,
          })}
          ref={tooltipRef}
        >
          <video
            width="250"
            autoPlay
            muted
            loop
            className={styles.video}
            ref={videoRef}
            webkit-playsԻnline
            playsInline
          >
            <source src={gif} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>,
        document.body
      )}
    </>
  );
};

export default Tooltip;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_mainPage__zyQON {
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 73px;
}

@media (max-width: 1190px) {
  .App_mainPage__zyQON {
    width: unset;
    margin: 0 24px;
  }
}
@media (max-width: 901px) {
  .App_mainPage__zyQON {
    width: unset;
    margin: 0 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE;IACE,YAAA;IACA,cAAA;EACF;AACF;AAEA;EACE;IACE,YAAA;IACA,cAAA;EAAF;AACF","sourcesContent":[".mainPage {\n  width: 1140px;\n  max-width: 100%;\n  margin: 0 auto;\n  padding-top: 73px;\n}\n\n@media (max-width: 1190px) {\n  .mainPage {\n    width: unset;\n    margin: 0 24px;\n  }\n}\n\n@media (max-width: 901px) {\n  .mainPage {\n    width: unset;\n    margin: 0 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainPage": `App_mainPage__zyQON`
};
export default ___CSS_LOADER_EXPORT___;

import styles from "./hero.module.scss";
import { FC, useEffect, useState } from "react";
import classNames from "classnames";

interface HeroProps {
    language?: string;
}

const Hero: FC<HeroProps> = ({ language }) => {
    const [scrolled, setScrolled] = useState(false);
    const [disableArrow, setDisableArrow] = useState(false);
    const name = language === "ar" ? "فريق ايليا" : `Team Elea`;
    const descriptionEn = `*A special unit for many years operating in stealth mode on highly classified governmental projects in the Middle East.`;
    const descriptionAr =
        "وحدة خاصة مميزة تعمل لسنوات عديدة في وضع التخفي في مشاريع حكومية شديدةّ السرية في الشرق الأوسط*";
    const description = language === "ar" ? descriptionAr : descriptionEn;

    const scrollToContent = () => {
        window.scroll({
            top: 600,
            behavior: "smooth",
        });
        setScrolled(true);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (scrolled) {
                setDisableArrow(true);
            }
        }
            , 700);
        return () => clearTimeout(timer);
    }, [scrolled]);

    return (
        <div className={styles.hero}>

            <video
                className={
                    styles.video
                }
                controls={false}
                webkit-playsԻnline
                playsInline
                autoPlay
                muted
                loop
                src="/images/video.m4v"
            />
            <h1 className={styles.title}>{name} <span className={styles.asterisk}> *</span></h1>
            <p className={styles.description}> {description}</p>
            <button
                className={classNames(styles.scrollBtn, {
                    [styles.scrolled]: scrolled,
                    [styles.disableArrow]: disableArrow,
                })}
                onClick={scrollToContent}
            >
                <img src={"/icons/arrow-down.png"} width={24} height={24} />
            </button>
        </div>
    );
};

export default Hero;

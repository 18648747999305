import { FC } from "react";
import classNames from "classnames";

import AnimatedFrame from "../animatedFrame/animatedFrame";
import Block from "../block/block";

import styles from "./infoSection.module.scss";

interface InfoSectionProps {
  language?: string;
}

const InfoSection: FC<InfoSectionProps> = ({ language }) => {
  const titleEn = "/ CONSULTING - ADVISORY - AUDIT";
  const titleAr = "/ الاستشارات - النصائح - التدقيق";
  const title = language === "ar" ? titleAr : titleEn;
  const description1En =
    "Building complex software? We have seen companies waste millions on such projects because of a lack of proper understanding of business requirements, poor project descriptions, documentation, and weak RFPs, leading to unclear tender goals.";
  const description1Ar =
    "هل تقوم ببناء برامج معقدة؟ لقد رأينا الشركات تُهدر الملايين على مثل هذه المشاريع بسبب الإفتقار إلى الفهم الصحيح لمتطلبات المشروع، وسوء وصف المشروع، والوثائق، وضُعف طلبات تقديم العروض (RFP)، مِمَّا يؤدي إلى أهداف غير واضحة للمناقصة.";
  const description1 = language === "ar" ? description1Ar : description1En;

  const description2En =
    "Our primary expertise is in orchestrating highly-professional process of business analysis, talking to customers, generating requirements, and preparing world-class documentation and designs.";
  const description2Ar =
    "تتمثل خبرتنا الأساسية في تنسيق عملية تحليل الأعمال عالية الاحترافية، والتحدث مع العملاء، وتوليد المتطلبات، وإعداد الوثائق والتصميمات وخرائط الطريق ذات المستوى العالمي.";
  const description2 = language === "ar" ? description2Ar : description2En;

  const description3En =
    "We deliver Product Requirement Documents (PRDs), exceptional UI-UX designs, and prototypes, ensuring it covers all your product needs. You will just need to pass this documentation and designs to the software engineering team of your choice to code.";
  const description3Ar =
    "نحن نقدم مستندات متطلبات المنتج (PRDs)، وتصميمات تجربة المستخدم (UI-UX) الاستثنائية، والنماذج الأولية، مِمَّا يضمن أنها تغطي جميع احتياجات منتجك. ستحتاج فقط إلى مشاركة هذه الوثائق والتصميمات مع فريق هندسة البرمجيات الذي تختاره للبرمجة المشروع.";
  const description3 = language === "ar" ? description3Ar : description3En;

  const companiesEn = "Companies established and brought to acquisition";
  const companiesAr = "شركات تم تأسيسها وتقديمها للاستقطاب ";
  const companies = language === "ar" ? companiesAr : companiesEn;

  const developedProjectsEn = "Projects developed";
  const projectsDevelopedAr = "تم تطوير 100 مشروع";
  const projectsDeveloped =
    language === "ar" ? projectsDevelopedAr : developedProjectsEn;

  const teamMembersEn = "Team members";
  const teamMembersAr = "أعضاء الفريق";
  const teamMembers = language === "ar" ? teamMembersAr : teamMembersEn;

  const managedPortfolioEn = "Managed product portfolio worth";
  const managedPortfolioAr = " تبلغ قيمة محفظة المنتجات المدارة";
  const managedPortfolio =
    language === "ar" ? managedPortfolioAr : managedPortfolioEn;

  return (
    <>
      <div className={styles.infoSection}>
        <div className={styles.txtWrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description1}</p>
          <p className={styles.description}>{description2}</p>
          <p className={styles.description}>{description3}</p>
        </div>
        <div className={styles.frameWrapper}>
          <AnimatedFrame
            arabic={language === "ar"}
            imageUrl={"/images/rectangle.png"}
            width={312.54}
            height={180.5}
            line
          />
        </div>
      </div>
      <div className={styles.infoSection2}>
        <AnimatedFrame
          imageUrl={"/images/circle.png"}
          className={styles["circle-frame"]}
          width={293}
          height={174}
        />
        <div className={styles.blocks}>
          <div className={styles.firstPart}>
            <Block
              className={classNames(
                styles["first-block"],
                language === "ar" && styles["arabic-block"]
              )}
              imageUrl={"/icons/content-logo.svg"}
              number={3}
              info={companies}
            />
            <Block
              className={classNames(
                styles["second-block"],
                language === "ar" && styles["arabic-second-block"]
              )}
              imageUrl={"/icons/stats-logo.svg"}
              number={"$500m+"}
              info={managedPortfolio}
            />
          </div>
          <div className={styles.secondPart}>
            <Block
              className={classNames(
                styles["third-block"],
                language === "ar" && styles["arabic-third-block"]
              )}
              imageUrl={"/icons/projects-logo.svg"}
              number={"100+"}
              info={projectsDeveloped}
            />
            <Block
              className={classNames(
                styles["fourth-block"],
                language === "ar" && styles["arabic-fourth-block"]
              )}
              imageUrl={"/icons/team-logo.svg"}
              number={6}
              info={teamMembers}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoSection;

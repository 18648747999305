import React, {FC} from "react";
import styles from "./header.module.scss";
import LanguageSwitcher from "../LanguageSwitcher";

interface HeaderProps {
  openContacts?: (prev: boolean) => void;
  language?: string;
}

const Header:FC<HeaderProps> = ({openContacts, language}) => {
    const contactsTxt = language === "ar" ? "جهات الاتصال" : "Contacts";
  return (
    <header className={styles.header}>
      <img src={"/icons/logo.svg"} width={86} height={24} />
      <div className={styles.leftSide}>
        <button className={styles.btn}
        onClick={() => openContacts && openContacts(true)}
        > {contactsTxt}</button>
          <LanguageSwitcher/>
      </div>
    </header>
  );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/BlenderPro-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/BlenderPro-Book.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/BlenderPro-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/BlenderPro-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/BlenderPro-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background-color: #000;
  color: #fff;
  font-family: "Blender Pro Thin", serif;
  overflow-x: hidden;
}
body h2 {
  text-transform: uppercase;
  font-size: 32px;
  margin: 0;
  line-height: 35.2px;
}
body hr {
  max-width: 626px;
  background-color: #fff;
  margin-left: 0;
  width: 100%;
}

@font-face {
  font-family: "Blender Pro Thin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Blender Pro Book";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Blender Pro Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Blender Pro Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Blender Pro Heavy";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@media (max-width: 901px) {
  hr {
    opacity: 0.45;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,sBAAA;EACA,WAAA;EACA,sCAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;EACA,eAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAEE;EACE,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;AAAJ;;AAKA;EACE,+BAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AAFF;AAMA;EACE,+BAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AAJF;AAQA;EACE,iCAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AANF;AAUA;EACE,+BAAA;EACA,+DAAA;EACA,iBAAA;EACA,kBAAA;AARF;AAYA;EACE,gCAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AAVF;AAaA;EACE;IACE,aAAA;EAXF;AACF","sourcesContent":["body {\n  margin: 0;\n  background-color: #000;\n  color: #fff;\n  font-family: \"Blender Pro Thin\", serif;\n  overflow-x: hidden;\n\n  h2 {\n    text-transform: uppercase;\n    font-size: 32px;\n    margin: 0;\n    line-height: 35.2px;\n  }\n\n  hr {\n    max-width: 626px;\n    background-color: #fff;\n    margin-left: 0;\n    width: 100%;\n  }\n}\n\n//Thin font\n@font-face {\n  font-family: \"Blender Pro Thin\";\n  src: url(\"/fonts/BlenderPro-Thin.ttf\") format(\"truetype\");\n  font-weight: 100;\n  font-style: normal;\n}\n\n// Regular font\n@font-face {\n  font-family: \"Blender Pro Book\";\n  src: url(\"/fonts/BlenderPro-Book.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n// Medium font\n@font-face {\n  font-family: \"Blender Pro Medium\";\n  src: url(\"/fonts/BlenderPro-Medium.ttf\") format(\"truetype\");\n  font-weight: 500;\n  font-style: normal;\n}\n\n// Bold font\n@font-face {\n  font-family: \"Blender Pro Bold\";\n  src: url(\"/fonts/BlenderPro-Bold.ttf\") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n//Heavy font\n@font-face {\n  font-family: \"Blender Pro Heavy\";\n  src: url(\"/fonts/BlenderPro-Heavy.ttf\") format(\"truetype\");\n  font-weight: 900;\n  font-style: normal;\n}\n\n@media (max-width: 901px) {\n  hr {\n    opacity: 0.45;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

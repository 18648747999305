import { TrustedByContainer } from "../trustedByContainer";

import { FC } from "react";

import styles from "./trustedBy.module.scss";

type TrustedByProps = {
  language?: string;
};

const TrustedBy: FC<TrustedByProps> = ({ language }) => {
  const trustedByEn = [
    {
      name: "UAE PRESIDENTAL COURT",
      imgSrc: "/images/presidential-court.png",
      imgScrMobile: "/images/presidential-court-mobile.png",
    },
    {
      name: "UAE MOI",
      imgSrc: "/images/moi.png",
      imgScrMobile: "/images/moi-mobile.png",
    },
    {
      name: "UAE THE CABINET",
      imgSrc: "/images/the-cabinet.png",
      imgScrMobile: "/images/the-cabinet-mobile.png",
    },
    {
      name: "UAE PSN",
      imgSrc: "/images/psn.png",
      imgScrMobile: "/images/psn-mobile.png",
    },
    {
      name: "UAE NLA",
      imgSrc: "/images/nla.png",
      imgScrMobile: "/images/nla-mobile.png",
    },
    {
      name: "UAE SZGM",
      imgSrc: "/images/szgm.png",
      imgScrMobile: "/images/szgm-mobile.png",
    },
    {
      name: "UK ROYAL AIR FORCE",
      imgSrc: "/images/air-force.png",
      imgScrMobile: "/images/air-force-mobile.png",
    },
    {
      name: "USAID",
      imgSrc: "/images/usaid.png",
      imgScrMobile: "/images/usaid-mobile.png",
    },
  ];
  
  const trustedByAr = [
    {
      name: "ديوان الرئاسة في دولة الإمارات العربية المتحدة",
      imgSrc: "/images/presidential-court.png",
      imgScrMobile: "/images/presidential-court-mobile.png",
    },
    {
      name: "وزارة الداخلية الإماراتية",
      imgSrc: "/images/moi.png",
      imgScrMobile: "/images/moi-mobile.png",
    },
    {
      name: "مجلس الوزراء في دولة الإمارات العربية المتحدة",
      imgSrc: "/images/the-cabinet.png",
      imgScrMobile: "/images/the-cabinet-mobile.png",
    },
    {
      name: "PSN في دولة الإمارات العربية المتحدة",
      imgSrc: "/images/psn.png",
      imgScrMobile: "/images/psn-mobile.png",
    },
    {
      name: "الأرشيف والمكتبة الوطنية في دولة الإمارات العربية المتحدة",
      imgSrc: "/images/nla.png",
      imgScrMobile: "/images/nla-mobile.png",
    },
    {
      name: "جامع الشيخ زايد الكبير في دولة الإمارات العربية المتحدة",
      imgSrc: "/images/szgm.png",
      imgScrMobile: "/images/szgm-mobile.png",
    },
    {
      name: "سلاح الجو الملكي البريطاني",
      imgSrc: "/images/air-force.png",
      imgScrMobile: "/images/air-force-mobile.png",
    },
    {
      name: "الوكالة الأمريكية للتنمية الدولية",
      imgSrc: "/images/usaid.png",
      imgScrMobile: "/images/usaid-mobile.png",
    },
  ];

  const trustedBy = language === "ar" ? trustedByAr : trustedByEn;

  const titleEn = "/ TRUSTED BY";
  const titleAr = "/ موثوق به";
  const title = language === "ar" ? titleAr : titleEn;
  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}> {title} </h2>
      <div className={styles.containers}>
        {trustedBy.map((item, index) => (
          <TrustedByContainer
            key={index}
            imgSrc={item.imgSrc}
            imgScrMobile={item.imgScrMobile}
            language={language}
            txt={item.name}
          />
        ))}
      </div>
    </section>
  );
};

export default TrustedBy;

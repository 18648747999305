import { FC } from "react";
import classNames from "classnames";

import Container from "../container/container";

import styles from "./contribution.module.scss";

type ContributionProps = {
  language?: string;
};

const Contribution: FC<ContributionProps> = ({ language }) => {
  const titleEn = "/ our contribution to open-source";
  const titleAr = "/ مساهمتنا في المصادر المفتوحة";
  const title = language === "ar" ? titleAr : titleEn;
  const contributionsEn = [
    {
      title: "biggest public library of nudging strategies",
      url: "https://keepsimple.io/uxcore",
    },
    {
      title: "company management framework",
      url: "https://keepsimple.io/company-management",
    },
  ];
  const contributionsAr = [
    {
      title: "أكبر مكتبة عامة لاستراتيجيات الدفع",
      url: "https://keepsimple.io/uxcore",
    },
    {
      title: "إطار إدارة الشركة",
      url: "https://keepsimple.io/company-management",
    },
  ];
  const contributions = language === "ar" ? contributionsAr : contributionsEn;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      {contributions.map((contribution, index) => (
        <Container
          key={index}
          title={contribution.title}
          className={classNames(styles["container"], [styles[`item-${index}`]])}
          url={contribution.url}
          clickable
          removeArrowOnMobile
          imgUrl={"/icons/new-page-icon.png"}
          iconHeight={30}
          iconWidth={30}
        />
      ))}
    </div>
  );
};

export default Contribution;

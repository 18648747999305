import React, {FC} from "react";
import {createPortal} from "react-dom";
import cn from "classnames";

import styles from "./contactsModal.module.scss";
import {Contacts} from "../contacts";

type ContactsModalProps = {
    onClick?: (prev: boolean) => void;
    language?: string;
};

const ContactsModal: FC<ContactsModalProps> = ({onClick, language}) => {
    const contactsTxtEn = "/ contacts";
    const contactsTxtAr = "/ جهات الاتصال";
    const title = language === 'ar' ? contactsTxtAr : contactsTxtEn;
    const telegramTxtEn = "Telegram";
    const telegramTxtAr = "تليجرام ";
    const telegramTxt = language === 'ar' ? telegramTxtAr : telegramTxtEn;


    const handleClose = () => {
        onClick && onClick(false);
    }


    return createPortal(
        <div className={cn(styles.overlay, {
            [styles.rtl]: language === 'ar',
        })}>
            <div className={styles.background} onClick={handleClose}/>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <span className={styles.title}>{title}</span>
                    <img
                        src="/icons/closing-icon.png"
                        alt="modal close button"
                        className={styles.closeBtn}
                        onClick={handleClose}
                        width={32}
                        height={32}
                    />
                </div>
                <div className={styles.body}>
                    <div className={styles.desktopView}>
                        <hr className={styles.liner}/>
                        <Contacts language={language}/>
                    </div>
                </div>
                <div className={styles.mobileView}>
                    <a href={"https://t.me/highness"} className={styles.mobileBtn}> <img
                        src={"/icons/telegram-icon.png"} width={16} height={16}/> {telegramTxt} </a>
                    <a href={"mailto:wolf@elea.co"} className={styles.mobileBtn}> <img
                        src={"/icons/mail-icon.png"} width={16} height={16}/>  wolf@elea.co </a>
                    <a href={"mailto:natalie@elea.co"} className={styles.mobileBtn}><img
                        src={"/icons/mail-icon.png"} width={16} height={16}/> natalie@elea.co </a>
                </div>
                <div className={styles.btnWrapper}>
                    <button className={styles.button} onClick={handleClose}>
                        Close
                    </button>
                </div>
            </div>

        </div>,
        document.body
    );
};

export default ContactsModal;

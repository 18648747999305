import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./Layout/Home";
import disableConsole from "./utils/disableConsole";

function App() {
  disableConsole();

  return (
    <main>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ar/*" element={<Home language="ar" />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LanguageSwitcher.module.scss";

const LanguageSwitcher = () => {
  const navigate = useNavigate();

  const handleLanguageChange = (language: string) => {
    const newPath =
      language === "ar"
        ? "/ar" + window.location.pathname
        : window.location.pathname.replace("/ar", "");
    navigate(newPath, { replace: true });
  };

  return (
    <>
      {window.location.pathname === "/ar/" ? (
        <div
          onClick={() => handleLanguageChange("en")}
          className={styles.languageSwitcher}
        >
          <img src={"/icons/uk-flag.svg"} width={20} height={20} />
          <span>English</span>
        </div>
      ) : (
        <div
          onClick={() => handleLanguageChange("ar")}
          className={styles.languageSwitcher}
        >
          <img src={"/icons/uae-flag.svg"} width={20} height={20} />
          <span>العربي</span>
        </div>
      )}
    </>
  );
};

export default LanguageSwitcher;

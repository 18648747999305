// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_content__llcV5 {
  display: flex;
  gap: 16px;
  width: auto;
  max-height: 102px;
  height: 100%;
}
.block_content__llcV5 .block_number__8Ckes {
  font-size: 24px;
}
.block_content__llcV5 .block_info__UqjhN {
  width: 100%;
  max-width: 220px;
  font-size: 18px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/block/block.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;AAAJ","sourcesContent":[".content {\r\n  display: flex;\r\n  gap: 16px;\r\n  width: auto;\r\n  max-height: 102px;\r\n  height: 100%;\r\n\r\n  .number {\r\n    font-size: 24px;\r\n  }\r\n\r\n  .info {\r\n    width: 100%;\r\n    max-width: 220px;\r\n    font-size: 18px;\r\n    margin: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `block_content__llcV5`,
	"number": `block_number__8Ckes`,
	"info": `block_info__UqjhN`
};
export default ___CSS_LOADER_EXPORT___;

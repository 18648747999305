// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollToTop_wrapper__\\+Rk3Y {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}
.scrollToTop_wrapper__\\+Rk3Y .scrollToTop_btn__v7TGU {
  all: unset;
  font-size: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/scrollToTop/scrollToTop.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACE,UAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".wrapper {\r\n  text-transform: uppercase;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  margin-bottom: 32px;\r\n\r\n  .btn {\r\n    all: unset;\r\n    font-size: 24px;\r\n    display: flex;\r\n    gap: 16px;\r\n    align-items: center;\r\n    cursor: pointer;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `scrollToTop_wrapper__+Rk3Y`,
	"btn": `scrollToTop_btn__v7TGU`
};
export default ___CSS_LOADER_EXPORT___;
